.alert {
  padding: 0.5rem;
  margin-bottom: 0.6rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  display: flex;
}

.alert-danger {
  background-color: #fef0f0;
  border-color: #f5c2c7;
  color: #f56c6c;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &.alert-inversed-colors {
    background-color: #f56c6c;
    border-color: #f56c6c;
    color: white;
  }
}

.alert-success {
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;

  &.alert-inversed-colors {
    background-color: #67c23a;
    border-color: #67c23a;
    color: white;
  }
}

.alert-warning {
  background-color: #fdf6ec;
  border-color: #faecd8;
  color: #e6a23c;

  &.alert-inversed-colors {
    background-color: #e6a23c;
    border-color: #e6a23c;
    color: white;
  }
}

.alert-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #36cfc9;

  &.alert-inversed-colors {
    background-color: #36cfc9;
    border-color: #36cfc9;
    color: white;
  }
}
