.button-save-grey {
  background-color: #b9b9ba;
  color: #ffffff;
  width: 130px !important;
  border-radius: 5px !important;
}

.button-save-green {
  background-color: #009646 !important;
  color: #ffffff !important;
  width: 130px !important;
  border-radius: 5px !important;
}

.heading-form-mql {
  padding-bottom: 10px !important;
}

.alert-danger-bottom {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 93%;
  z-index: 999;
  position: relative;
  margin-bottom: 20%;
}

.space-below {
  margin-bottom: 0.1rem !important;
}

.bold-black {
  font-weight: bold;
  color: black;
}

// aligned with h6
.block {
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
}

.section-title {
  font-size: 14px;
  color: #3e3e40;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 6px;
}

.section-title-label {
  text-decoration: underline;
  margin-left: 3px;
}

#sc-form input {
  margin-bottom: 4px;
}

#sc-form .front-hover-parent {
  margin-bottom: 4px;
}

#sc-form .form-select {
  margin-bottom: 4px;
}
