.btn-validate-grey {
  background-color: #b9b9ba;
  color: #ffffff;
  width: 120% !important;
  border-radius: 5px !important;
}

.spaceTop {
  padding-top: 6rem;
}
