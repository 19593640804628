.date-input-group {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  display: flex;
  flex-flow: row;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  border: 2px solid transparent;
  min-height: 30px;
  max-width: unset;
  background: rgb(243, 244, 246);
  margin-bottom: 0.4rem;
}

.date-input-group:hover {
  background: rgb(232, 234, 237);
}

.date-input-container {
  display: flex;
  flex-flow: row;
  flex: 1 1 0%;
  overflow: hidden;
  place-content: center;
}

.date-input-arrow-block {
  display: flex;
  flex-flow: column;
  place-content: center;
  padding: 0px 8px;
}

.date-input-icon {
  margin-left: 8px;
  place-content: center;
  display: grid;
}

.date-input {
  flex: 1 1 0%;
  padding: 0px 0px 0px 8px;
  line-height: 26px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: normal;
  color: rgb(25, 25, 26);
  cursor: default;
  user-select: none;
  outline: none !important;
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  appearance: none;
  border: 2px solid transparent;
  margin-bottom: 0px !important;
}

.placeholder {
  color: rgb(187, 189, 191);
  opacity: 1;
}

.time-view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.5rem;
}

.time-view-date {
  width: 100%;
  display: block;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 0.1rem;
}

.time-view-date:hover {
  background: rgb(232, 234, 237);
}

.time-view-input {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: center;
}

.time-view-select {
  width: 6rem !important;
  margin-bottom: 0px !important;
  background: rgb(243, 244, 246);
  border: 2px solid transparent;
  border-radius: 6px;
  outline: none !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: rgb(25, 25, 26);
  cursor: pointer;
}

.rdtPicker {
  z-index: 1500 !important;
}
