body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$primaryColor: hsl(220, 1%, 47%);
$primaryDarkerColor: hsl(220, 1%, 34%);
$bgColor: hsl(220, 1%, 77%);
$bgLighterColor: hsl(180, 3%, 92%);

$redColor: hsl(355, 84%, 57%);
$greenColor: hsl(95, 68%, 33%);
$blueColor: hsl(216, 84%, 57%);

section {
  padding-bottom: 0.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  margin: 0.2rem 0 0.5rem 0;
}

h5 {
  color: $primaryDarkerColor;
  font-size: 1rem;
}

h6 {
  color: $primaryColor;
  font-size: 0.85rem;
}

span.required {
  color: $redColor;
  padding-left: 0.3rem;

  &:after {
    content: '*';
  }
}

div.auth-error {
  color: $redColor;
  padding-left: 0.3rem;
}

p {
  font-size: 0.9rem;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}

.none {
  display: none;
}

.block {
  color: $primaryColor;
  font-size: 0.85rem;
  padding-bottom: 8px;

  .label {
    font-weight: bold;
  }

  .label:after {
    content: ' : ';
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  box-sizing: border-box;
  height: 100%;

  .content {
    flex: 1;

    input {
      margin-bottom: 0.6rem;
    }

    padding-bottom: 4rem;
  }

  .bottom {
    margin-top: auto;
  }

  .bottom-fixed {
    position: fixed;
    min-height: 4rem;
    inset: auto 0 0 0;
    background: #fff;
  }
}

.gap {
  &-top {
    margin-top: 0.6rem;
  }

  &-bottom {
    margin-bottom: 0.6rem;
  }

  &-sides {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

.items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  border-radius: 5px !important;
  margin-top: 0.6rem !important;
  cursor: pointer !important;

  &-cancel {
    color: #333 !important;
    text-decoration: underline;
  }

  &-success {
    background-color: $greenColor !important;
  }

  &-right {
    margin-left: auto;
  }
}

.select {
  &-row {
    > * {
      margin-bottom: 0.6rem;
    }
  }

  &-list {
    &.item {
      cursor: pointer;
      padding: 2px;
      margin-bottom: 0.3rem;
      font-size: 0.85rem;
      color: $primaryDarkerColor;
      display: flex;
      border-radius: 10px;

      &:hover {
        background: $bgLighterColor;
      }
    }
  }
}

.modal {
  position: absolute;
  inset: 0;
  background-color: hsla(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

#modal {
  width: 250px;

  &.container {
    background: #fff;
    border-radius: 8px;
    height: auto;
    width: 90%;
  }
}

.btn-bottom-right {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.order-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  bottom: 1rem;
  width: 100%;
  padding: 0 0.1rem;
}

.order-container-emoji {
  width: 25%;
  font-size: 2.8125rem;
  text-align: center;
  margin: 0;
}

.order-container-txt {
  width: 75%;
  font-size: 0.82rem;
  text-align: left;
}

.btn-right-enregistrer {
  position: fixed;
  bottom: 1rem;
  right: 0.75rem;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  color: #77787a;
}

.space-between-icon-text {
  padding-right: 1rem;
}

.deconnexion-link {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: right;
  text-decoration: underline;
  display: block;
  margin-bottom: 0.75rem;
  &:hover {
    color: $blueColor;
    cursor: pointer;
  }
}

.no-scroll {
  overflow: hidden;
}

@import 'app';
@import 'components/form/_form';
